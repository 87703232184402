import axios from 'axios'

// TODO: Разделить файл helpers на работу с датой и остальное
// TODO: Написать JSDos для функций из файла

// Получает объект отдельного вопроса
// Проверяет завершен этот вопрос или нет
export function isQuestionDone(question) {
  if (!question.answerId) return false
  if (question.answerId === 2 && !question.comment) return false
  return true
}

// Глубоко копирует объект через JSON-parse-stringify
export function copyObject(object) {
  if (typeof object !== 'object') return object
  return JSON.parse(JSON.stringify(object))
}

// Форматирует проценты в строку читаемый вид
export function formatPercent(number) {
  return Math.floor(number * 100) + '%'
}

// Проверяет на IE
export function isIE() {
  const ua = window.navigator.userAgent //Check the userAgent property of the window.navigator object
  const msie = ua.indexOf('MSIE ') // IE 10 or older
  const trident = ua.indexOf('Trident/') //IE 11
  return msie > 0 || trident > 0
}

// Типограф:
// ищет 1 или 2 значимых символа окруженных пробельными символами
// Заменяет последний пробел в найденном на неразрывный
export function typograpf(string) {
  return isIE() ? string : string.replaceAll(/(\s\S{1,2})\s/g, '$1\xa0')
}

// Загружает файл
// TODO: Поставил в проект file-saver можно все переключить на него!
export function downloadFile(fileId, fileName) {
  axios
    .get(`/api/file/${fileId}?download&attachment`, {
      responseType: 'blob',
    })
    .then(res => {
      const blob = new Blob([res.data], {
        type: res.headers['content-type'],
      })
      const link = window.URL.createObjectURL(blob)
      const anchor = document.createElement('a')
      anchor.href = link
      anchor.download = fileName
      anchor.click()
      window.URL.revokeObjectURL(link)
    })
}

// Форматирует строку с датой в читаемый вид YYYY-MM-DD
// TODO: Возможно стоит отказаться от этого displayDate и использовать везде russianDate
export function displayDate(datestring) {
  return new Date(datestring).toISOString().substr(0, 10)
}

/**
 * Returns a date formatted as string 'DD.MM.YYYY'. Expect string suitable for Date.parse()
 * https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/Date/parse
 * @param {string} datestring
 * @return {string} 'DD.MM.YYYY'
 */
export function russianDate(datestring) {
  return new Date(datestring)
    .toISOString()
    .substr(0, 10)
    .split('-')
    .reverse()
    .join('.')
}

// Возвращает строку, дата через 7 дней от текущей
const min = 60 * 1000
const days7 = 7 * 24 * 60 * min
export const NOW_PLUS_7D = displayDate(
  new Date(Date.now() - new Date().getTimezoneOffset() * min + days7)
)

// Мега-функция отдает объект со всеми отображениями для даты
export function getDisplayDayDifference(now, deadline) {
  if (!now || !deadline) return
  const dayStart = new Date(displayDate(now))
  const dayEnd = new Date(displayDate(deadline))
  const diff = Math.floor((dayEnd - dayStart) / 86400000)

  const RESULT_TEMPLATE = {
    diff,
    textWithDays: `Через ${diff} ${getReadableDays(diff)}`,
    icon: 'mdi-alert-circle',
    color: 'amber',
    shortText: '',
  }

  if (diff < 0) {
    return {
      ...RESULT_TEMPLATE,
      shortText: 'Просрочено',
      textWithDays: `Просрочено на ${-diff} ${getReadableDays(diff)}`,
      color: 'red',
    }
  }
  if (diff === 0) {
    return {
      ...RESULT_TEMPLATE,
      shortText: 'Сегодня',
      textWithDays: `Сегодня`,
      color: 'orange',
    }
  }
  if (diff === 1) {
    return {
      ...RESULT_TEMPLATE,
      shortText: 'Завтра',
    }
  }
  if (diff === 2) {
    return {
      ...RESULT_TEMPLATE,
      shortText: 'Послезавтра',
    }
  }
  if (diff > 2) {
    return {
      ...RESULT_TEMPLATE,
      color: 'green',
      icon: false,
    }
  }
}

// внутренняя функция. Возвращает склонение дней
function getReadableDays(num) {
  const absNum = Math.abs(num)
  const rest100 = absNum % 100
  if (rest100 % 100 >= 11 && rest100 <= 14) {
    return 'дней'
  }
  const rest10 = absNum % 10
  if (rest10 === 1) {
    return 'день'
  }
  if (rest10 >= 2 && rest10 <= 4) {
    return 'дня'
  }
  return 'дней'
}
