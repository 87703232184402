<template>
  <v-app-bar
    app
    dense
    hide-on-scroll
    class="elevation-4"
    color="blue lighten-4"
  >
    <div>Система для проведения экспертизы цифровизации КР</div>
    <v-spacer></v-spacer>
    <div class="mr-2 font-weight-bold">{{ readableRole }}:</div>
    <div class="mr-4">{{ getLoggedUserFio }}</div>
    <v-icon @click="onLogout" title="Выход">mdi-exit-to-app</v-icon>
  </v-app-bar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { USER_ROLES } from '@/store/const/userRoles'

export default {
  computed: {
    ...mapGetters(['getLoggedUserRole', 'getLoggedUserFio']),
    readableRole() {
      const role = USER_ROLES.find(r => r.value === this.getLoggedUserRole)
      return role ? role.roleName : 'Не залогинен'
    },
  },
  methods: {
    ...mapActions(['logout']),
    async onLogout() {
      this.logout().then(() => {
        this.$router.push('/login')
      })
    },
  },
}
</script>

<style scoped>
.exit-button {
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.2s ease;
}
.exit-button:hover {
  opacity: 0.8;
}
</style>
