<template>
  <div>
    <AppBar />
    <v-main class="mt-8">
      <CenterContent>
        <slot></slot>
      </CenterContent>
    </v-main>
    <v-footer app color="blue lighten-5">
      <CenterContent>
        <div class="body-2 my-1">
          &copy;&nbsp;2021&nbsp;ФГБУ &laquo;Центр экспертизы и&nbsp;контроля
          качества медицинской помощи&raquo; Минздрава России
        </div>
      </CenterContent>
    </v-footer>
  </div>
</template>

<script>
import AppBar from '@/components/layout/AppBar.vue'
import CenterContent from '@/components/layout/CenterContent.vue'

export default {
  components: {
    AppBar,
    CenterContent,
  },
}
</script>
